


















import '@/icons';
import ToolTip from "@/components/custom/ToolTip.vue";
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'nav-item',
  components: {
    ToolTip
  }
})
export default class NavItem extends Vue {
  @Prop({default: false}) selected?: boolean;
  @Prop() icon?: string;
  @Prop({default: 0}) counter?: number;
  @Prop({default: 16}) size?: number;
  @Prop({default: '#FF4F4F'}) bgColor?: string;
  @Prop({default: '#FFFFFF'}) color?: string;
  @Prop({default: ''}) title?: string;
  @Prop({default: ''}) tooltip?: string;


  get badge() {
    return {
      width: this.size + 'px',
      height: this.size + 'px',
      top: (this.size! / 3) + 'px',
      right: (this.size!) + 'px',
      borderRadius: (this.size! / 2) + 'px',
      backgroundColor: this.bgColor,
      textAlign: 'center'
    };
  }

  get font() {
    return {
      fontSize: (this.size! / 2) + 'px',
      lineHeight: this.size + 'px',
      color: this.color,
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 'bold'
    };
  }
}
